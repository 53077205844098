<template>
    <Header/>
    <Onsiteheader/>
    <Onsiteservice/>
    <OnsiteSection/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Onsiteservice from '@/components/Onsiteservice.vue'
import OnsiteSection from '@/components/OnsiteSection.vue'
import Onsiteheader from '@/components/Onsiteheader.vue'

export default {
  components: {
     Header,
    Footer,
    Onsiteservice,
    OnsiteSection,
    Onsiteheader
  }
}
</script>