<template>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>What we do</h2>
          <p style="padding-top:20px">We recruit within the premises of our clients company. The benefits to the client are innumerable since the employee gained would comply with the company policies by the use of finding the talent pool where there will be an ever on-going recruitment.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-md-4 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 style="text-align:center;">{{ obj.header }}</h4>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Employer-Team.jpg',
        header: 'On site recruitment team',
           },
      {
        path: 'services/Attract-Talents.jpg',
        header: 'Attract Global Talents',
        },
      {
        path: 'services/Engaging-Staff.jpg',
        header: 'Engaging Staff',
        }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#services {
    padding: 50px 0px 10px 0px;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 25px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}


</style>